<template>
  <div id="app">
    <keep-alive>
      <a-config-provider :locale="locale">
        <router-view />
      </a-config-provider>
    </keep-alive>
  </div>
</template>

<script>
// 国际化语言
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
export default {
  data() {
    return {
      locale: zhCN,
    };
  },
};
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100vh;
}

</style>
