import { request, METHOD } from "@/utils/request";
import api from "./api";

export const getDocument = (params) => {
  return request(api.DOCUMENT, METHOD.POST, params);
};
export const getPassword = (params) => {
  return request(api.DOCUMENT_PASSWORD, METHOD.POST, params);
};
export const getCodes = params => {
  return request(api.GET_CODE, METHOD.POST,params)
}
// 详情
export const getDocumentDetail = params => {
  return request(api.DOCUMENT_DETAIL, METHOD.POST,params)
}
