import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoading: false,//加载动画
    request: false,//重新请求简体值
  },
  mutations: {
    UP_LOADING(state, value){
      state.isLoading = value
    },
    REQUEST(state){
      state.request = !state.request
    }
  },
  actions: {
  },
  modules: {
  }
})
