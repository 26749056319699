import axios from "axios";

// http method
const METHOD = {
  GET: "get",
  POST: "post",
};

/**
 * axios请求
 * @param url 请求地址
 * @param method {METHOD} http method
 * @param params 请求参数
 * @returns {Promise<AxiosResponse<T>>}
 */
async function request(url, method, params) {
  switch (method) {
    case METHOD.GET:
      return axios.get(url, { params });
    case METHOD.POST:
      return axios.post(url, params);
    default:
      return axios.get(url, { params });
  }
}

// 添加请求拦截器
axios.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    // console.log("请求前");
    config.headers.language = localStorage.getItem("web_language");
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    // console.log("请求后", response);
    return response;
  },
  function (error) {
    // 对响应错误做点什么, 超出 2xx 范围的状态码都会触发该函数。
    return Promise.reject(error);
  }
);

export { METHOD, request };
