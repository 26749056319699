<template>
	<div style="display: flex;height: 100%;width: 100%;">
		<template v-if="show">
			<a-spin :spinning="loading" style="display: flex;height: 100%;">
				<div style="display: flex;height: 100%;">
					<div class="left" style="overflow: auto;">
						<a-menu mode="inline" style="width: 100%;height: 80vh;" @openChange="onOpenChange"
							:default-open-keys="[...defaultOpenKeys]" :default-selected-keys="[...defaultSelectedKeys]"
							v-if="isTree">
							<a-sub-menu :key="item.key" v-for="item in treeList">
								<span slot="title">
									<span>
										<a-icon type="bars" />{{ item.title }}
									</span>
								</span>
								<template v-for="arr in item.children">
									<template v-if="arr.children.length == 0">
										<a-menu-item :key="arr.key" @click="handleItem(arr)">
											{{ arr.title }}
										</a-menu-item>
									</template>
									<template v-else>
										<a-sub-menu :key="arr.key" :title="arr.title">
											<template v-for="two in arr.children">
												<template v-if="two.children.length == 0">
													<a-menu-item :key="two.key" @click="handleItem(two)">
														{{ two.title }}
													</a-menu-item>
												</template>
												<template v-else>
													<a-sub-menu :key="two.key" :title="two.title">
														<a-menu-item :key="iss.key" v-for="iss in two.children"
															@click="handleItem(iss)">
															{{ iss.title }}
														</a-menu-item>
													</a-sub-menu>
												</template>
											</template>
										</a-sub-menu>
									</template>
								</template>
							</a-sub-menu>
						</a-menu>
					</div>
					<div class="right">
						<h1>{{ title }}</h1>
						<v-md-editor :value="mdValue" mode="preview"></v-md-editor>
					</div>
				</div>
			</a-spin>
		</template>
		<template v-else>
			<a-empty style="width: 100%;margin-top: 10%;" />
		</template>
		<a-modal :title="$t('home.form1')" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk"
			@cancel="handleCancel">
			<a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
				<a-form-item :label="$t('home.form2')">
					<a-input :placeholder="$t('home.form2')"
						v-decorator="['project_name', { rules: [{ required: true, message: $t('home.form2') }] }]"
						disabled />
				</a-form-item>
				<a-form-item :label="$t('home.form3')">
					<a-input :placeholder="$t('home.form3')"
						v-decorator="['password', { rules: [{ required: true, message: $t('home.form3') }] }]" />
				</a-form-item>
				<a-form-item :label="$t('home.form4')">
					<a-input :placeholder="$t('home.form4')"
						v-decorator="['captcha', { rules: [{ required: true, message: $t('home.form4') }] }]" />
				</a-form-item>
				<a-form-item :label="$t('home.form5')">
					<Canvas :identifyCode="numCodeText" @handle="_getCode" />
				</a-form-item>
			</a-form>
		</a-modal>
		<!-- 密码弹框 -->
	</div>
</template>

<script>
import Canvas from "../../components/canvas/index.vue";
import { userService } from "../../services";
import { decrypt, Encrypt } from "../../utils/utils";
export default {
	name: "",
	components: { Canvas },
	data() {
		return {
			treeList: [],
			ModalText: 'Content of the modal',
			visible: false,
			confirmLoading: false,
			form: this.$form.createForm(this),
			loading: false,
			// 验证码
			numCodeText: '',
			client_rand: '',
			expandedKeys: [],
			contextList: [],//点击数据
			mdValue: '',
			title: '',
			// 
			show: true,
			// url地址参数
			urlTitle: "",
			urlObj: [],
			// 默认暂时目录
			defaultOpenKeys: [],
			isTree: true,//默认暂时数据时需要重新渲染
			defaultSelectedKeys: [0]
		}
	},
	created() {
		this.handleTree()
		this._getCode()
	},
	computed: {
		// 语言切换
		language() {
			return this.$store.state.request;
		},
	},
	watch: {
		defaultOpenKeys(new_val, old_val) {
			this.$store.commit('UP_LOADING', true)
			// console.log('新',new_val);
			// console.log('旧',old_val);
			setTimeout(() => {
				this.isTree = true
				this.$store.commit('UP_LOADING', false)
			}, 200);
		},
		// 状态变换重新请求接口
		language(new_val, old_val) {
			// console.log('new_val', new_val);
			// console.log('old_val', old_val);
			this.handleTree()
		}
	},
	methods: {
		handleItem(item) {
			// console.log('当前点击', item);
			// console.log('titlename', this.urlTitle);
			let newUrl = 'title=' + this.urlTitle + '&' + `id=${item.key}`

			console.log('当前地址', newUrl);
			newUrl = '#/?' + Encrypt(newUrl)
			window.history.replaceState('', '', newUrl)
			this._getDocumentDetail(item.key)
		},
		onOpenChange(openKeys) {
			console.log('openKeys', openKeys);
		},
		// 随机数
		mathStr(m, n) {
			const result = Math.round(Math.random() * (m - n) + n)
			return result
		},
		// 验证码
		_getCode() {
			this.numCode = this.mathStr(400000, 600000)
			userService
				.getCodes({
					client_rand: this.numCode
				}).then(res => {
					const { code, msg, data } = res.data
					console.log("🚀 ~ _getCode ~ data:", data)
					if (code !== 200) {
						console.log('21312');
						this.$message.error(msg);
					} else {

						this.numCodeText = data.captcha + ''
						this.client_rand = data.client_rand
					}
				})
		},
		showModal() {
			this.visible = true;
			console.log(this.urlTitle)
			this.$nextTick(() => {
				this.form.setFieldsValue({
					project_name: this.urlTitle,
				});

			})
		},
		handleOk() {
			this.confirmLoading = true
			this.form.validateFields((error, values) => {
				if (!error) {
					userService
						.getPassword({
							client_rand: this.client_rand,
							...values
						})
						.then((res) => {
							this.confirmLoading = false
							const { code, msg, data } = res.data;
							if (code !== 200) {
								this.$message.error(msg);
							} else {
								this.treeList = this.funTree(data)
								this.visible = false
								this.handleTree()

							}
						})
						.catch((error) => {
							this.confirmLoading = false
							this.$message.error(error);
						});
				}
			});
		},
		handleCancel() {
			console.log('取消');
			this.visible = false;
		},
		// 目录列表
		handleTree() {
			let url = ''
			Object.keys(this.$route.query).forEach(key => {
				console.log('key:', key, 'value:', this.$route.query[key]);
				url = key
			})
			console.log('url', url);
			let str = ''
			if (url.indexOf(' ')) {
				url = url.split(' ')
				url.map((item) => {
					str = str + item + "+"
				})
				str = str.slice(0, str.length - 1)
				url = str
			}
			let titleName = decrypt(url)
			console.log('解密', titleName);

			titleName = titleName.slice(6, titleName.length)
			console.log('title', titleName.indexOf('&'));
			if (titleName.indexOf('&') == -1) {
				// console.log('进来了');
			} else {
				// 有其它参数
				this.urlObj = titleName.split('&')
				this.urlObj[1] = this.urlObj[1].slice(3, this.urlObj[1].length)
				console.log('多数据', this.urlObj);
				titleName = this.urlObj[0]
			}
			// console.log('使用数据', titleName);
			this.urlTitle = titleName
			if (titleName == 'TikTok商城主后台') {
				localStorage.setItem("web_language", 'zh');
			}
			this.$store.commit('UP_LOADING', true)
			userService
				.getDocument({
					project_name: titleName
				}).then((res) => {
					let { data, msg, code } = res.data
					if (code == 414) {
						this.showModal()//打开密码输入
					} else if (code == 200) {
						this.show = true
						this.$store.commit('UP_LOADING', false)
						// console.log('data', data);
						this.treeList = this.funTree(data)
						// console.log('目录', this.treeList);
						if (this.urlObj[1]) {
							// 有id值
							this._getDocumentDetail(this.urlObj[1])
						} else {
							// 无id值默认数据
							this._getDocumentDetail(this.treeList[0].children[0].key)
						}
					} else {
						this.$message.error(msg);
					}
				})
		},
		// 结构树递归函数
		funTree(data) {
			return data.map((item) => {
				this.expandedKeys.push(item.id)
				return {
					key: item.id,
					status: item.status,
					pid: item.pid,
					title: item.directory_name ? item.directory_name : '',
					content: item.content,
					children: !item.children_list ? [] : this.funTree(item.children_list)
				}
			})
		},
		// 内容
		_getDocumentDetail(item) {
			console.log("🚀 ~ _getDocumentDetail ~ item:", item)

			this.$store.commit('UP_LOADING', true)
			userService
				.getDocumentDetail({
					id: item
				}).then((res) => {
					let { data, msg, code } = res.data
					if (code == 200) {
						this.$store.commit('UP_LOADING', false)
						console.log('data', data);
						if (this.defaultOpenKeys.length == 0) {
							this.isTree = false
							this.defaultOpenKeys = [data.pid, data.id]
							console.log('defaultOpenKeys', this.defaultOpenKeys);
							this.defaultSelectedKeys = [data.id]
						}
						this.title = data.directory_name
						this.mdValue = data.content ? data.content : '暂无内容'
						// this.mdValue = this.mdValue.replace("13zh-CN0000zh-CN", "10000000zh-CN");
						this.mdValue = this.mdValue.replace(/13zh0000zh-CN/g, "10000000zh-CN");
						console.log('数据', this.mdValue);
					} else {
						this.$message.error(msg);
					}
				})
		}
	},
};
</script>

<style lang="scss" scoped>
/* 隐藏滚动条 */
.left::-webkit-scrollbar {
	display: none;
}

.left {
	width: 15vw;
	height: 90vh;
	border-right: 2px solid #F5F5F5;
	padding: 10px 0px;
	min-width: 140px;
	font-size: 16px;
}

.right::-webkit-scrollbar {
	display: none; //隐藏滚动条
}

.right {
	width: 85vw;
	// height: 100%;
	padding: 10px;
	// overflow-x: hidden;
	// overflow-y: scroll;
	overflow: auto;
	height: 90vh;

	>h1 {
		margin-left: 30px;
	}
}
</style>