<template>
	<div style="width: 100%;height: 100%;">
		<div style="height: 74px;min-width: 500px;">
			<homeTitle />
		</div>
		<div style="height: calc(100% - 74px);">
			<a-spin :spinning="this.$store.state.isLoading">
				<homeContext />
			</a-spin>
		</div>
	</div>
</template>

<script>
import { Encrypt } from "../utils/utils";
import homeContext from "./components/homeContext.vue";
import homeTitle from "./components/homeTitle.vue";
export default {
	name: "",
	components: { homeTitle, homeContext },
	data() {
		return {
		}
	},

	created() {
		let title = Encrypt('title=量化总代理后台')
		// console.log('title加密',title);
	},
	methods: {

	},
};
</script>

<style lang="scss" scoped></style>