//网络请求地址
// const BASE_URL = "https://yiketianqi.com";
const BASE_URL = "/api";
module.exports = {
  // admin_login
  // DOCUMENT: `${BASE_URL}/admin_login`,
  DOCUMENT: `${BASE_URL}/document/list`,
  DOCUMENT_DETAIL: `${BASE_URL}/document/findById`,
  DOCUMENT_PASSWORD: `${BASE_URL}/document/login`,
  GET_CODE: `${BASE_URL}/document/captcha`,//验证码
};
