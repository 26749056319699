<template>
    <div>
        <div class="title">
            <div class="left">
                <img src="../../assets/img/gsLogo.png" class="logo" alt="" srcset="">
                <div>{{ $t('home.title') }}</div>
            </div>
            <div class="right">
                <img src="../../assets/img/language.png" class="logo" alt="">
                <!-- -->
                <div>
                    <a-dropdown style="color: #fff;position: relative;top: 33px;right: 100px;">
                        <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                            {{ language }}
                        </a>
                        <a-menu slot="overlay" style="margin-top: 10px;" v-if="language!=='简体'">
                            <!-- <a-menu-item @click="handle('zh')">
                                <span>简体</span>
                            </a-menu-item> -->
                            <a-menu-item @click="handle('en')">
                                <span>English</span>
                            </a-menu-item>
                            <a-menu-item @click="handle('zh-HK')">
                                <span>繁體</span>
                            </a-menu-item>
                        </a-menu>
                    </a-dropdown>
                    <!-- <div class="share">
                        <a-icon type="tag" />
                        分享链接
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "",
    data() {
        return {
            language: localStorage.getItem("web_language")
        }
    },
    activated(){
        if(this.language == "en"){
            this.language = 'English'
        }
        else if(this.language == "zh"){
            this.language = '简体'
        }
        else if(this.language == 'hk'){
            this.language = '繁體'
        }else{
            this.language = '繁體'
        }
    },
    methods: {
        handle(item) {
            this.$store.commit('REQUEST')
            localStorage.setItem("web_language", item);
            // if (item == 'zh') {
            //     this.language = '简体'
            //     this.$i18n.locale = 'zh'
            // } else 
            if (item == 'en') {
                this.language = 'English'
                this.$i18n.locale = 'en'
            } else {
                this.language = '繁體'
                this.$i18n.locale = 'hk'
            }
            this.$router.go(0)
        }
    },
};
</script>

<style lang="scss" scoped>
.share {
    color: #fff;
    position: relative;
    top: 23px;
    right: 20px;
    min-width: 80px;
}

.title {
    // background-color: #C9C5C5;
    width: 100%;
    height: 74px;
    background-image: url('../../assets/img/bgTitle.png');
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;

    >.left {
        display: flex;
        font-size: 30px;
        font-weight: bold;
        line-height: 70px;
        margin-left: 60px;
        color: #fff;

        >.logo {
            width: 55px;
            height: 55px;
            margin-top: 10px;
            margin-right: 10px;
        }
    }

    >.right {
        line-height: 7px;
        margin-right: 7px;
        display: flex;

        >.logo {
            margin-top: 23px;
            margin-right: 110px;
            width: 30px;
            height: 30px;
        }
    }
}</style>