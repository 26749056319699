import en from "./en";
import zh from "./zh";
import hk from "./hk";
import VueI18n from "vue-i18n";
import Vue from "vue";

Vue.use(
    VueI18n
)
const i18n = new VueI18n({
    // locale: localStorage.getItem("web_language")=='zh'?'zh':localStorage.getItem("web_language")=='zh-HK'?'hk':localStorage.getItem("web_language")=='en'?'en':'zh',
    locale: localStorage.getItem("web_language")=='en'?'en':'hk',
    messages:{
        en:{
            ...en,
        },
        zh:{
            ...zh,
        },
        hk:{
            ...hk,
        }
    }
})
export default i18n