import CryptoJS from "crypto-js";

// 加密方法
export function Encrypt(word) {
  console.log("===========>word", word);
  const key = CryptoJS.enc.Utf8.parse("TMNOAfuXpoupY7O1");
  const iv = CryptoJS.enc.Utf8.parse("2s0p8vvvjfuxg3z4");
  word = JSON.stringify(word);
  const srcs = CryptoJS.enc.Utf8.parse(word); // 将数据转换成十六进制 (hex格式)

  var encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString(); // 返回 base64编码
}

export const decrypt = (str) => {
  console.log('解密', str);
  const key = CryptoJS.enc.Utf8.parse("TMNOAfuXpoupY7O1");
  const iv = CryptoJS.enc.Utf8.parse("2s0p8vvvjfuxg3z4");
  const decryptedData = CryptoJS.AES.decrypt(str, key, {
    iv,
    padding: CryptoJS.pad.Pkcs7,
  });
  const result = decryptedData.toString(CryptoJS.enc.Utf8);
  return JSON.parse(result);
};
